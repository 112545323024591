'use client'

import { ClerkProvider } from '@clerk/nextjs'
import { ThemeProvider } from 'next-themes'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

const env = process.env.DEPLOYMENT_ENV

if (typeof window !== 'undefined' && env === 'production') {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY || 'defaultKey'
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST || 'defaultHost'

  posthog.init(posthogKey, {
    api_host: posthogHost,
  })
}
export function Providers({ children }) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <ClerkProvider>
          <ThemeProvider attribute="class" disableTransitionOnChange>
            {children}
          </ThemeProvider>
        </ClerkProvider>
      </PostHogProvider>
    </>
  )
}
