import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true,\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true,\"variable\":\"--font-sans\"}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikLight.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikLightItalic.otf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikRegular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikRegularItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikMedium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikMediumItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikSemibold.otf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Graphik-Font-Family/GraphikSemiboldItalic.otf\",\"weight\":\"600\",\"style\":\"italic\"}],\"variable\":\"--font-sans\",\"preload\":true}],\"variableName\":\"graphik\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Cormorant_Garamond\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true,\"variable\":\"--font-serif\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"cormorantGaramond\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/providers.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/sitewide/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/tailwind.css");
